html{

    background-color: #002031; 
    overscroll-behavior: none;
   
}
iframe{
    
    background-color: #002031; 
  
}

:root {
    background-color: #002031; 
  --button-dark-mode-color:#0051835b;
  --site-font: -apple-system, BlinkMacSystemFont, sans-serif;
  
}

#searchInput
{
    background-color: #01385ab0;
  
border-color: transparent;
    border-radius: 10px;
 
    color: white;
   
    
}

#pageLink
{

  color: white;
  text-decoration: none;

}


#feedSearchInput
{

    background-color: #01385ab0;
  
    border-color: transparent;
        border-radius: 10px;
     
        color: white;
        width: 300px;
}

#searchForm
{
    background-color: #002539;
 
padding-right: 10px;
padding-left: 10px;
padding: 10px;

    color: white;
}

#searchFormStateReps {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Distribute space between elements */
    background-color: #002539;
    padding: 10px;
    color: white;
  }
  

#audioButton
{
    padding-left: 60px;
    
    
    
}

#textButton
{
    padding-left: 60px;
    
    
    
}


#houseCommitteeOnScienceSpaceAndTechnology, #houseCommitteeOnScienceSpaceAndTechnology2, #democracyHumanRightsLabor, #democracyHumanRightsLabor2, #stateDepartmentPress, #stateDepartmentPress2
{

    position: relative;
    
    color: white;
}
#travelNotices, #travelNotices2, #foodSafety, #foodSafety2, #foodRecalls, #foodRecalls2, #medWatch, #medWatch2, #healthyLivingFeatures, #healthyLivingFeatures2, #preventingChronicDisease, #preventingChronicDisease2, #covid19, #covid192, #novelCoronavirus, #novelCoronavirus2, #vaccineUpdates, #vaccineUpdates2, #seasonalFlu, #seasonalFlu2,
#eia, #eia2
{

    position: relative;
    
    color: white;
}

#outbreaks, #outbreaks2{
    position: relative;
    
    color: white;
}

#houseCommitteeOnIntelligence, #houseCommitteeOnIntelligence2{
    position: relative;
    
    color: white;
}

#unTopStories, #unTopStories2{
    position: relative;
    
    color: white;
}

#unJournal, #unJournal2{
    position: relative;
    
    color: white;
}

#unNewsAmericas, #unNewsAmericas2, #armsControl, #armsControl2, #counterterrorism, #counterterrorism2, #democracyHumanRightsLabor, #democracyHumanRightsLabor2,
#dosPress, #dosPress2, #diplomaticSecurity, #diplomaticSecurity2

{
    position: relative;
    
    color: white;
}


#iframeCol{
    border-radius: 20px;
}

#iframeColMobile{
    border-radius: 20px;
}



#navbarStates{
    font-family: var(--site-font);
       font-weight: bold;
        left: 30px;
       bottom: 75px;

       padding-bottom: 20px;
       padding-left: 100px;
      background-color: transparent;
   margin-left: 0em;
       
      
   
   }

   #footerContent{
    font-family: var(--site-font);
      font-weight: bold;
      font-size: 20px;
      color: white;
      
     position: relative;
     border-color: transparent;
     padding-bottom: 50px;
  
  
  
  }


#navbar{
 font-family: var(--site-font);
 position: relative;
    font-weight: bold;
    margin: 0px;
    padding-left: 38px;
   background-color: #002031; 
margin-left: 0em;
/* Prevent horizontal overflow */
}

#navbar .navbar {
    align-items: flex-start !important; /* Or whatever alignment fits your design */
  }



#navbarMobile{

    font-weight: bold;

    background-color: #002031;
   /* OLD COLOR: background-color: #002539; */
   font-family: var(--site-font);
    height: 100px
  

}

#navbarSearchLogo{

    font-weight: bold;

    background-color: #002031;
   /* OLD COLOR: background-color: #002539; */
   font-family: var(--site-font);
    
  

}

#navbarSearchLogo{

    font-weight: bold;

    background-color: #002031;
   /* OLD COLOR: background-color: #002539; */
   font-family: var(--site-font);
    
  

}




#navbarMobileFooter{

    font-weight: bold;

    background-color: #002031;
   /* OLD COLOR: background-color: #002539; */
   font-family: var(--site-font);
    height: 100px

}



#statesDropDown
{
    font-weight: bold;
 
  background-color: #02476c; 
   font-family: var(--site-font);
    
   
  
}

#offcanvasButton
{
border-color: transparent;
    border-radius: 50px;
    background-color: #002031;
    position: absolute;
    top: 0;
    bottom: 10px;
    left: 0;
    right: 0;
    height: 60px;
    width: 60px;
    margin-top: calc(6.5%);
    margin-left: calc(85%);
    
}

#offcanvasButton2
{
border-color: transparent;
    border-radius: 50px;
    background-color: #03699d;
    margin-right: 5px;
    margin-left: 20px;
    height: 60px;
    width: 60px;
    padding-top: 20px;
position: relative;

    
}



#mobileDropdown
{

 
    border-color: transparent;
 

}


.offcanvas-backdrop.show {
    opacity: .0;
}

#mobileOffcanvas
{
    color: white;
    
    width: 50%;
    background-color: #033b5a; 
    border-color: transparent;
    outline-color: transparent;
    
    backdrop-filter: none;
}

#offcanvasDropdown
{
    border-color: transparent;
    background-color: transparent;
    color: rgb(221, 221, 221);
    font-size: 20px;
}

#offcanvasDropdownHeader
{
    color: white;
    font-size: 24px;
}

#offcanvasDropdown
{
    
    background-color: transparent;
    border-color: transparent;
    
}


#offCanvasIcon
{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
   color: white;
}

#offcanvasDropdownHeader
{
    color: white;
    font-size: 24px;
    background-color: transparent;
}

#offcanvasDropdownHeaderTwo
{
    color: white;
    font-size: 20px;
    background-color: transparent;
    padding: 4px;
}

#offcanvasDropdownHeaderThree
{
    color: white;
    font-size: 20px;
    background-color: transparent;
}

#offcanvasDropdownHeaderFour
{
    color: white;
    font-size: 22px;
    background-color: transparent;
}



#offcanvasDropdownItem
{
    color: white;
    background-color: #035279;
    border-color:transparent;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 4px;
    text-decoration: none;
    display: inline;

}

#navbarMobileBottom{
 
    font-weight: bold;
  background-color: transparent;
   font-family: var(--site-font);
    
   position: fixed;
   bottom: 0;
   width: 100%;
   z-index: 1000; /* Adjust the z-index as needed */

}

#iframeModalHeader{

    color: black;
}

#homeCarousel{
    
}

#carouselInColumn
{
    height: 280px;
}

#houseCarousel
{
    height: auto;
}

#healthCarousel
{

  
}

#podcastCarousel{


width: 1100px;
}


#home{
    font-size: 30px;
    color: #ff4040;
    padding-right: 10px;
    padding-top: 15px;
    

    

}

#mobileLogo{
    font-size: 30px;
    margin: auto;
    position: relative;
    bottom: 8px;
}


#realNavbarTop
{   
    position: relative;
   
    margin-left: 0em;

}

#testRow {
    display: flex;
    flex-wrap: wrap;
  }
  
  .button-container-nav {
    display: flex;
    flex-wrap: wrap;
  }
  
  #bottomNavbar .nav-link {
    max-width: 200px; /* Adjust as needed */
    margin-right: 10px;
    margin-bottom: 10px;
    flex-shrink: 1; /* Allow items to shrink if needed */
}
  

#navTopRow
{
    padding-top: 5px;
    padding-left: 20px;
    


}
#navTopMobile
{
    padding-left: 10px;
    

}

#navbarGovGlance
{
    font-family: var(--site-font);
    font-weight: bold;
    font-size: 24px;
    color: #ff4040;
    padding-left: 00px;
    padding-right: 20px;
    padding-top: 20px;

}
#mobileNavButtons
{
    padding-top: 40px;
}

#congressSearch
{
    height: calc(50%);
    width: 400px;
     background-color: #0051835b ;
     border-radius: 20px;
    color: white;
    border: 0px;
   
}

.large-item {
    transform: scale(1.2); /* Increase size by 20% */
    transition: transform 0.3s ease; /* Add smooth transition effect */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add shadow for depth effect */
  }
  
  .large-item:hover {
    transform: scale(1.3); /* Increase size on hover */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4); /* Enhance shadow on hover */
  }

#navbarSearch
{

    height: 36px;
    width: 50vw;
     background-color: #0051835b ;
     border-radius: 20px;
    color: white;
    border: 0px;
    
}

#navbarSearchHome
{

    height: 36px;
    width: 70vw;
     background-color: #0051835b ;
     border-radius: 20px;
    color: white;
    border: 0px;
    
}
#whiteHouseSearch
{
    width: 400px;
     background-color: #0051835b ;
    color: white;
}

#noteModalFeedItem
{
  
    width: calc(98%);
     background-color: #0051835b ;
    color: white;
    border: 0px;
    border-radius: 20px;
}





#noteModal
{
    width: 400px;
     background-color: #0051835b ;
    color: white;
}

#noteModalMobile
{
    width: calc(100%);
     background-color: #0051835b ;
    color: white;
}



#supremeCourtSearch
{
    width: 400px;
     background-color: #0051835b ;
    color: white;
}

#sloganHeading
{
    font-size: 24px;
}

#branchHeadingBold
{
    font-size: 24px;

    padding-left: 15px;
}

#congressSearchButton
{
    position: relative;
    background-color: var(--button-dark-mode-color);
    border-color:transparent;
    height: 40px;
    left: 410px;
    bottom: 55px;


}

#clickChevron
{
    height: 15px;
}

#whiteHouseSearchButton
{
position: relative;
    background-color: var(--button-dark-mode-color);
    border-color:transparent;
    height: 40px;
    left: 410px;
    bottom: 35px;


}

#legislativePageButton
{
    background-color: rgba(1, 56, 90, 0.24);
    border-color:transparent;
    margin-right: 5px;
    margin-left: 5px;
   
    
}

#legislativePageButton2
{
    background-color: rgba(1, 56, 90, 0.24);
    border-color:transparent;
   font-size: 11px;
    
}

#statesDropDown
{
    
    border-color:transparent;
   
    
}

#viewFullLegislativePageButton
{
    background-color: var(--button-dark-mode-color);
    border-color:transparent;
    position: relative;
    left: 30px;
    top: 100px;
    width: 400px;
    
    
 
   
    
}

#viewFullLegislativePageButtonDesk
{
    background-color: var(--button-dark-mode-color);
    border-color:transparent;
    position: relative;
   
    top: 100px;
 
    
    
 
   
    
}

#viewFullPageButton
{
    padding-left: 20px;
    padding-right: 20px;
}

#viewFullLegislativePageButtonMobile
{
    background-color: var(--button-dark-mode-color);
    border-color:transparent;
    color: white;
    height: calc(100%);
    text-align: center;
    width: calc(100%);
    position: relative;
    top: 90px;

    
    
 
   
    
}

#canadaProvinceDesktop
{
    padding-top: 15px;
}

#canadaProvinceDesktopHeading
{
    padding-top: 25px;
}


#branchNavbarPageButtonPadding
{
    
    
}




#allPageButton
{
    background-color: rgba(1, 56, 90, 0.24);
    border-color:transparent;
    margin-right: 5px;
    margin-left: 5px;

}

#homeButton
{
    background-color: var(--button-dark-mode-color);
    border-color:transparent;
    

}



#judicalPageButton
{
    background-color: rgba(1, 56, 90, 0.24);
    margin-right: 5px;
    margin-left: 5px;
    border-color:transparent;
    
}

#executivePageButton
{
    background-color: rgba(1, 56, 90, 0.24);
    border-color:transparent;
    margin-right: 5px;
    margin-left: 5px;
}




#supremeCourtSearchButton
{
    position: relative;
    background-color: var(--button-dark-mode-color);
    border-color:transparent;
    height: 40px;
    left: 410px;
    bottom: 55px;


}

#homeCol{
    height: 525px;
    width: calc(100%);
    position: relative;
    border-radius: 20px;
}

#legislativeColRight, #topicColRight{
  
       position: relative;

        border-radius: 20px;

}

#carouselColumn
{
    height: 500px;


}

#healthColRight{
    height: 525px;
    width: 525px;
       position: relative;
    
    
   

        border-radius: 20px;
  





}

#podcastColRight{
    height: 525px;
    
       
    
    
   

        border-radius: 20px;
  





}

#columnRightPadding{
    padding: 1px;
}





#columnLefttPadding,#columnLeftPadding {
    padding: 1px;
}


#colLeftPadding{
 
    padding-right: 0px;
}

#colRightPadding{
   
    padding-left: 0px;
}

#iframeDiv
{
    border-radius: 20px;
}

#iframeDivMobile
{
    border-radius: 20px;
}
#option{
  font-family: var(--site-font);
    font-weight: bold;
    font-size: 20px;
    color: white;
    padding-top: 20px;
    width: 350px; 
}  

#optionMobile{
 font-family: var(--site-font);
    font-weight: bold;
    font-size: 30px;
    color: white;
    margin: auto;
    text-align: center;
}

#optionAIMobile{
    font-family: var(--site-font);
       font-weight: bold;
       font-size: 24px;
       color: white;
       margin: auto;
       text-align: center;
   }

#navbarMobileRow
{

}

#mobileFoundingDocumentsDiv
{
    margin: auto;
}

#foundingDoucmentsMobileIcon
{
    
}



#navbarDocumentButtonDesktop{

        background-color: #0051835b ;
     border-color:transparent;
 font-family: var(--site-font);
     width: 250px;

}



#navbarDeclarationButtonDesktop{

        background-color: #0051835b ;
     border-color:transparent;
font-family: var(--site-font);
     width: 250px;
     

}




#navbarConstitutionButtonDesktop{

        background-color: #0051835b ;
     border-color:transparent;
font-family: var(--site-font);
     width: 150px;
     

}



#navbarBillOfRightsButtonDesktop{

        background-color: #0051835b ;
     border-color:transparent;
font-family: var(--site-font);
     width: 150px;
     

}

#navDocument{

    padding-top: 20px;

}

#betaTag{
font-family: var(--site-font);
    font-weight: bold;
    font-size: 20px;
    color: rgb(23, 252, 214);
    padding-left: 00px;
    padding-top: 23px;
    
  
}



#betaTagMobile{
font-family: var(--site-font);
    font-weight: bold;
    font-size: 20px;
    color: rgb(23, 252, 214);
    
}
/* still working on this - Active - change text color to white when selected */
.navbar-nav > .active { 
    background-color: white ; 
}




#fullBill{
    color: white;
    background-color: #002539;
    
}

#whf{
     color: white;
    background-color: #002539;
}

#iframeModalHeader{
 background-color: #002539;
 color: white;
 
 

}

#collectionModalHeader{

            background-color: #002539;
 color: white;
 border-color: #002539;
}


#aboutModalHeader{

            background-color: #002539;
 color: white;
 border-color: #002539;
 
}

#aboutInfo{
    color: white;
    background-color: #002539;
    border-color: #002539;
}

#aboutParagraph
{
    color: white;
    font-size: 16pt;
}
#aboutParagraphMobile
{
    color: white;
    font-size: 16pt;
    margin: 10px;
}

#mobileCollectionModalHeader{

            background-color: #002539;
 color: white;
 border-color: #002539;
}




#whiteHouseFeedModalHeader
{
        background-color: #002539;
 color: white;
 border-color: #002539;
}
#mobileWhiteHouseFeedModalHeader
{
            background-color: #002539;
 color: white;
 border-color: #002539;

}

#billModalHeader{

    background-color: #002539;
 color: white;
 border-color: #002539;
 
}


#mobileBillModalHeader{

        background-color: #002539;
 color: white;
 border-color: #002539;

}
#courtArgumentsModalHeader
{
        background-color: #002539;
 color: white;
 border-color: #002539;

}
#fullUSCourtNewsModalHeader
{
           background-color: #002539;
 color: white;
 border-color: #002539; 
}

#mobileFullUSCourtNewsModalHeader
{
            background-color: #002539;
 color: white;
 border-color: #002539;    
}

#whiteHouseDocsModalHeader
{
        background-color: #002539;
 color: white;
 border-color: #002539;
}

#mobileWhiteHouseDocsModalHeader
{
        background-color: #002539;
 color: white;
 border-color: #002539;
}

.text-center {
    text-align: center;
  }



#fullBillFeed{
    padding: 10px;
        background-color: #002539;
        color: white;


        
}

#feedPageFeed
{
    padding: 10px;
    background-color: #002031; 
}

#searchPadding{
    padding-top: 20px;
    padding: 10px;

        color: white;


        
}

#shareButton {
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    border: 0px;
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    left: 30px;
  }



.modal-header .btn-close{
    background-color: white;
    
}

.btn-close:after

{
    
}


#fullBillFeedCollectionPage{
    padding: 10px;
        background-color: #002539;
        color: white;

        


        
}



#billFileText{
        background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);

}
#backButton
{
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#homebg{
    overflow-x: hidden; /* Prevent horizontal overflow */                 
      background-color: #002031; 
 
    
}

#shareFooter
{
  color: transparent;
  background-color: transparent;
  border: none;

}

#homebgNav{
            
      background-color: #002031; 
 
    
}
#whiteHouseFeed{
            background-color: #0051835b ;
    color: white;

}

#billLink{
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    border: 0px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    background-color: rgb(1, 56, 90);
    border-radius: 20px;

}

#moreSponsorsButton{
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    border: 0px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    background-color: rgb(1, 56, 90);
    border-radius: 20px;

}

.button-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  
  .button-container Button {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    border: 0px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    
  }

  .button-container Button:hover {
    background-color: rgb(1, 56, 90); /* Keep the background color the same */
    color: white; /* Keep the text color the same */
  }

#standardButton
{
    width: calc(100%);
    border: 0px;
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    
}

#feedViewMoreButton
{
    padding: 10px;
    width: 1320px;
    border: 0px;
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
}

#searchViewMoreButton
{
    width: 625px;
    border: 0px;
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    
}

#searchViewMoreButtonMobile
{
    width: calc(100%);
    border: 0px;
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    
}

#viewBillButton{
    position: relative;
        padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    border: 0px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    left: 10px;
    
}


#removeFromCollectionButton
{

    padding-top: 5px;
    padding-bottom: 5px;

   /* background-color: #0051835b ;*/
   background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    right: 500px;
    

}

#textLeft{
    color: white;
    margin-right: 10em;
    
}

#textRight{
    color: white;
    margin-left: 5em;
    
}

#bills, #billsIntroducedMobile{
    position: relative;
    color: white;
}



#whMobile
{
   
    position: relative;
    
    color: white;


}

#senateFloor{
    position: relative;
    
    color: white;
}

#houseFloor, #billStatus{
    position: relative;
    
    color: white;
}

#houseFloor2{
    position: relative;
    
    color: white;
}

#congressionalBudgetOffice, #congressionalBudgetOffice2, #congressionalBudgetOfficeMobile
{
position: relative;
    
color: white;
}

#executiveOrders, #executiveOrders2
{
    position: relative;
    
    color: white;

} 

#bills2{
    position: relative;
     
    color: white;
}

#bills3{
    position: relative;
     
    color: white;
}

#billsMobile{
    position: relative;
    
    color: white;
}

#bills2Mobile{
    position: relative;
        
        
    color: white;
}

#bills3Mobile{
    position: relative;
       
        
    color: white;
}

#trendingBills{
    position: relative;
     
    color: white;
    
}

#reports{
    position: relative;
    
    color: white;
}
#reports2{
    position: relative;
    
    color: white;
}

#gaoReports{
    position: relative;
    
    color: white;
}
#gaoReports2, #gaoReportsMobile{
    position: relative;
    
    color: white;
}


#houseCommitteeOnAppropriations{
    position: relative;
    
    color: white;
}

#houseCommitteeOnRules, #houseCommitteeOnRules2{
    position: relative;
    
    color: white;
}

#presidentialProclomations, #presidentialProclomations2
{
    position: relative;
    
    color: white;
}



#houseCommitteeOnRules, #houseCommitteeOnRules2{
    position: relative;
    
    color: white;
}

#houseCommitteeOnEnergyAndCommmerce,#houseCommitteeOnEnergyAndCommmerce2
{

    position: relative;
    
    color: white;

}

#houseCommitteeOnOversightAndReform, #houseCommitteeOnOversightAndReform2
{

    position: relative;
    
    color: white;

}

#congressionalHearings,#congressionalHearings2
{

    position: relative;
    
    color: white;

}

#houseCommitteeOnFinancialServices, #houseCommitteeOnFinancialServices2
{
   position: relative;
    
    color: white; 
}
#houseCommitteeOnTheJudiciary,#houseCommitteeOnTheJudiciary2
{
   position: relative;
    
    color: white; 
}

#houseCommitteeOnNaturalResources,#houseCommitteeOnNaturalResources2, #houseCommitteeOnNaturalResourceMobile
{
   position: relative;
    
    color: white; 
}

#houseCommitteeOnHouseAdministration,#houseCommitteeOnHouseAdministration2
{
   position: relative;
    
    color: white; 
}


#houseCommitteeOnHomelandSecurity, #houseCommitteeOnHomelandSecurity2
{
   position: relative;
    
    color: white; 
}

#houseCommitteeOnForeignAffairs, #houseCommitteeOnForeignAffairs2
{
   position: relative;
    
    color: white; 
}


#houseCommitteeOnEthics{
    position: relative;
    
    color: white;
}


#houseCommitteeOnAppropriations2{
    position: relative;
    
    color: white;
}


#houseComitteeOnAgriculture{
    position: relative;
    
    color: white;
}




#houseComitteeOnAgriculture2{
    position: relative;
    
    color: white;
}


#bea, #cit, #cit2{
    position: relative;
    
    color: white;
}

#bea2{
    position: relative;
    
    color: white;
}

#economicIndicators, #economicIndicators2
{
       position: relative;
    
    color: white; 
}

#sec{
    position: relative;
    
    color: white;
}

#sec2{
    position: relative;
    
    color: white;
}

#secPR, #secPR2{
    position: relative;
    
    color: white;
}

#sec2PR{
    position: relative;
    
    color: white;
}

#houseComitteeOnArmedServices{
    position: relative;
    
    color: white;
}

#houseComitteeOnArmedServices2{
    position: relative;
    
    color: white;
}

#houseCommitteeOnTheBudget{
    position: relative;
    
    color: white;
}

#houseCommitteeOnTheBudget2{
    position: relative;
    
    color: white;
}

#houseCommitteeOnEducationAndLabor{
    position: relative;
    
    color: white;
}


#houseCommitteeOnEducationAndLabor2{
    position: relative;
    
    color: white;
}

#wh, #councilOfEconomicAd, #councilOnEnvironment, #nationalEconomicCouncil,
#nationalSecurityCouncil,#officeOfIntergovernmentalAffairs, #officeOfManagmentAndBudget
{

    
    color: white;
}

.mobileContainer
{
    width: 350px;
}

.whDesktop{
  
    right: 25px;
   
}

.whMobile{

}

#whdocs{
    
    
    color: white;
   
}

#whdocs2{
    position: relative;
    
    
    color: white;
    
}

.whdocsDesktop{
    position: relative;
    
    width: 525px;
}

.whdocsMobile{
     position: relative;
    width: 350px;
    color: white;
}

#whd{
     color: white;
    background-color: #002539;
}



#courtNews, #courtOpinions, #courtArguments{

   
    
    color: white;
    
}


#courtNews2{

   
    
    color: white;
    
}

#courtNewsMobile{

   
    
    color: white;
    
}

#courtAppeals{

   
    
    color: white;
}


#courtAppeals2, #appealsFC, #appealsFC2, #appealsSC, #appealsSC2, #appealsTC, #appealsTC2, #appealsFourthC, #appealsFourthC2,
#appealsFifthC, #appealsFifthC2, #appealsSixthC, #appealsSixthC2, #appealsSeventhC, #appealsSeventhC2,
#appealsEC, #appealsEC2, #appealsNC, #appealsNC2, #appealsTenthC, #appealsTenthC2, #appealsEleventhC, #appealsEleventhC2,
#appealsDC, #appealsDC2, #appealsFederalC, #appealsFederalC2, #alabamaBills, #alabamaBills2, #alaskaBills, #alaskaBills2, 
#arizonaBills, #arizonaBills2, #arkansasBills, #arkansasBills2, #californiaBills, #californiaBills2,
#coloradoBills, #coloradoBills2, #connecticutBills, #connecticutBills2, #delawareBills, #delawareBills2, 
#floridaBills, #floridaBills2, #georgiaBills, #georgiaBills2, #hawaiiBills, #hawaiiBills2, #idahoBills, #idahoBills2,
#illinoisBills, #illinoisBills2, #indianaBills, #indianaBills2, #iowaBills, #iowaBills2, #kansasBills, #kansasBills2,
#kentuckyBills, #kentuckyBills2, #maineBills, #maineBills2, #louisianaBills, #louisianaBills2,
#marylandBills, #marylandBills2, #massachusettsBills, #massachusettsBills2, #michiganBills, #michiganBills2,
#minnesotaBills, #minnesotaBills2, #mississippiBills, #mississippiBills2, #missouriBills, #missouriBills2,
#montanaBills, #montanaBills2, #nebraskaBills, #nebraskaBills2, #nevadaBills, #nevadaBills2, #newHampshireBills, #newHampshireBills2, #newJerseyBills, #newJerseyBills2,
#newMexicoBills, #newMexicoBills2, #newYorkBills, #newYorkBills2, #northCarolinaBills, #northCarolinaBills2, #northDakotaBills, #northDakotaBills2, #ohioBills, #ohioBills2, #oklahomaBills, #oklahomaBills2, #oregonBills, #oregonBills2, #pennsylvaniaBills, #pennsylvaniaBills2,
#rhodeIslandBills, #rhodeIslandBills2, #southCarolinaBills, #southCarolinaBills2, #southDakotaBills, #southDakotaBills2, #tennesseeBills, #tennesseeBills2, #texasBills, #texasBills2, #utahBills, #utahBills2, #vermontBills, #vermontBills2, #virginiaBills, #virginiaBills2, #washingtonBills, #washingtonBills2, #westVirginiaBills, #westVirginiaBills2,
#wisconsinBills, #wisconsinBills2, #wyomingBills, #wyomingBills2, #alAgencies, #alabamaSoS, #alaskaGovernor
  {

   
    
    color: white;
}

#rightDropDown
{
  padding-left: 50px
}


#leftDropDown
{
  padding-right: 50px
}

#govGlanceLogo
{
  padding: 0px;
  margin: 0px;  
}

#courtAppealsMobile{

   
    
    color: white;
}

.courtNewsMobile{
    width: 350;
 
 
    
 
}

.courtNewsDesktop{
    position: relative;
    right: 25px;

}


#dailyDigest{
    display: grid;
    grid-template-columns: 8ch auto;
}
#legislativeCon  {
 padding: 20px;
 padding-bottom: 80px;
 height: 350px;
margin-top:     1em;
border-radius: 20px;
 margin-left: 0em;
margin-right: 0em;
background-color:  #002539;
}

#legislativeConInCol  {
    
    
   
    
    height: 200px;
   
   
   
   
      
       border-radius: 20px;
         margin-left: 0em;
      margin-right: 0em;
      
      background-color:  #002539;
   
      
   }

#billFeedHeadingMobileHome, #billFeedHeadingMobileHomeHome, #feedHeadingMobileHome, #presidentialFeedHeadingMobileHome
{


    padding-bottom: 0px;

       color: white;
       position: relative;
       font-size: 18px;
        font-family: var(--site-font);
}


#houseCon  {
    

   
      
   }


 #topicCon  {
    
    padding: 20px;
   
    padding-bottom: 80px;
    height: 350px;
   
   
   
       margin-top:     1em;
      
       border-radius: 20px;
         margin-left: 0em;
      margin-right: 0em;
      
      background-color:  #002539;

      
   }
   

#healthCon  {
    
    padding: 20px;
    
    padding-bottom: 80px;
    height: 350px;

   
   
       margin-top:     1em;
      
       border-radius: 20px;
         margin-left: 0em;
      margin-right: 0em;
      
      background-color:  #002539;

      
   }
   

#podcastCon  {
    
    padding: 20px;
    
    padding-bottom: 80px;
    height: 350px;
 
   
       margin-top:     1em;
      
       border-radius: 20px;
         margin-left: 0em;
      margin-right: 0em;
      
      background-color:  #002539;

      
   }

#placeHolderCarousel
{
    background-color: #002539;
}

#branchPage  {
    
 padding: 20px;
 padding-bottom: 80px;
 height: 350px;

    margin-top:     1em;
   
    border-radius: 20px;
      margin-left: 0em;
   margin-right: 0em;
    

   
}

#pageBottom  {
    
    padding: 20px;
    padding-bottom: 80px;
    height: 425px;
   
       margin-top:     1em;
      
       border-radius: 20px;
         margin-left: 0em;
      margin-right: 0em;
       background-color:  #002539;
   
      
   }

#executiveCon{
    position: relative;
     margin-left: 0em;
   margin-right: 0em;
    margin-top:     1em;
    padding: 20px;

      background-color:  #002539;

    border-radius: 20px;
 height: 350px;

}

#aboutInfo{
    color: white;
    background-color: #002539;
    border-color: #002539;
}



#allPageButtonMobile
{
    margin-top: 8px;
    background-color: #1994d1;
    border-color:transparent;
    margin-bottom: 8px;


}

#centerButton
{
    display: flex;
  justify-content: center;
  align-items: center;

}

#donateIcon
{
    padding-right: 2px;
    color: red;
}

.custom-table {
    color: white; /* Set text color to white */
  }
  
  .custom-table th,
  .custom-table td {
    border-color: white !important; /* Set border color to white */
  }


#spotifyIcon
{
    padding-right: 4px;
    
}

#stateFlag
{
    
    width: 60px;
    height: 55px;
}

#stateFlagNav
{
    
    width: 40px;
    height: 35px;
}

#provinceFlag
{
    right: 20px;
 
    width: 50px;
    height: 25px;

}

#stateFlagHomePage
{
  
    width: 35px;
}

#stateFlagMobile
{
  margin-right: 6px;
    width: 35px;
}


#branchesRow, #topicsRow
{

    padding: 20px;


}

#homeColumn, #topicColumn
{
    width: 33%;

}


#podcastColumn
{
   right: 5px;

}

#legislativeCarousel
{
    
}

#branchesTab
{
    overflow-x: hidden; /* Prevent horizontal overflow */
}



#legislativeConMobile {
    position: relative;

    margin-top: 0.5em;
    margin-bottom: 1em;

    padding-bottom: 20px;
    background-color: #012c477d;
   box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.521);
    border-radius: 20px;
    height: 300px;
    width: auto;
 min-height: 300px;
    max-height: 300px;
   
   


   
}

#legislativeConMobileHome {
    position: relative;

    margin-top: 0.5em;
    margin-bottom: 1em;
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: #012c477d;
   box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.521);
    border-radius: 20px;
    height: 300px;
    width: auto;
 
   
   


   
}



#congressionalRecord
{
    color: white;
}

#legislativeBills
{
 
    

}

#electionIconPadding
{

    margin-right: 5px;
}




#executiveConMobile{
    position: relative;

    margin-top: 0.5em;
    margin-bottom: 1em;
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: #012c477d;
   box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.521);
    border-radius: 20px;
    height: 300px;
    width: auto;
   
}

#judicalCon{
    position: relative;
   margin-left: 0em;
   margin-right: 0em;
    margin-top: 1em;
      background-color:  #002539;

    border-radius: 20px;
    padding: 20px;
    
 height: 350px;

}

#pagePadding{
    padding-top: 50px;
}


#judicalConMobile{
    position: relative;
  
   margin-bottom: 1em;
    margin-top: 0.5em;
      background-color: #012c477d;
   box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.521);
    border-radius: 20px;
   padding-top: 20px;
   padding-bottom: 20px;
   height: 300px;
}

#branchHeading{
position: relative;
    left: 30px;
    color: white;
  text-align: left;
  font-family: var(--site-font);
  margin: auto;
  height: 50px;
  overscroll-behavior: none;
 
  
 

}

#branchHeadingPicker
{
    color: white;
    position: relative;
    table-layout: fixed;
    font-family: var(--site-font);
    text-align: center; /* Center the text horizontally */
    margin: 0 auto; /* Center the element horizontally */
    top: 50%; /* Move the element down by 50% of its own height */
    transform: translateY(-50%); /* Adjust for the element's height to center vertically */
    margin-bottom: 5px;

}

#homePageBranchRow, #topicPageBranchRow
{
   
   
}


#branchesHeadingUS{

     font-family: var(--site-font);
    color: white;
    /*padding-top: 12px;*/
  right: 6px;


}

#branchesHeading
{
    position: relative;
     font-family: var(--site-font);
    color: white;
    right: 6px;


}
#branchPageButtonRow
{
    font-family: var(--site-font);
    position: relative; 
    margin-left: 3em;

   padding-left: 77px;

}

#unitedStatedFlag
{
    width: 46px;
    
    
}

#unitedStateFlagPadding
{
    padding-right: 20px;
    padding-top: 18px;
    
}

#unitedStateFlagMobilePadding
{
    padding-left: 25px;
    padding-right: 20px;
    
}

#branchPageBackgroundColor
{
    background-color: #023453b0;
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.521);
    

}


#executiveOfficeCarousel
{
    
}


#stateFlagHeadingMobile{

    color: white;
  text-align: left;
  padding-left: 20px;
font-family: var(--site-font);
margin: auto;
padding-bottom: 15px;
}




#branchHeadingMobile{

    color: white;
  text-align: left;
  padding-left: 20px;
font-family: var(--site-font);
margin: auto;

}
#feedHeading
{   
    padding-bottom: 25px;
       color: white;
       position: relative;
        font-family: var(--site-font);
      
  
}

#feedHeadingBillsEnrolled
{   
    padding-bottom: 25px;
       color: white;
       position: relative;
       right: 0px;
        font-family: var(--site-font);
       
      
      
  
}

#feedHeadingMobile
{   padding-top: 10px;
    padding-bottom: 0px;
       color: white;
       position: relative;
       font-size: 18px;
        font-family: var(--site-font);
      
  
}

b{
    font-size: 20px;
}
#billFeedHeading{

      padding-top: 0px;
    padding-bottom: 25px;
       color: white;
       position: relative;
        font-family: var(--site-font);
  

}

#billFeedHeadingElection{

    padding-top: 0px;
  padding-bottom: 5px;
     color: white;
     position: relative;
      font-family: var(--site-font);


}
#mobileFeedHeadingRow
{
    padding-left: 20px;
    
  
}

#offCanvas
{
    color: white;
    
    
}

#billFeedHeadingMobile{

    padding-top: 20px;
  padding-bottom: 0px;

     color: white;
     position: relative;
     font-size: 18px;
      font-family: var(--site-font);
     

}

#presidentialFeedHeading{
         
    padding-bottom: 25px;
       color: white;
       position: relative;
       font-family: var(--site-font);
       

}

#departmentOfStateHeading{
            
    padding-bottom: 25px;
       color: white;
       font-family: var(--site-font);
       

}


#departmentOfDefenseHeading{
            
    padding-bottom: 25px;
       color: white;
       font-family: var(--site-font);
       

}

#departmentOfDefenseHeadingInCol{
            
    padding-bottom: 10px;
       color: white;
       font-family: var(--site-font);
       

}
#departmentOfTreasuryHeading{
            
    padding-bottom: 25px;
       color: white;
       position: relative;
      font-family: var(--site-font);

}

#departmentOfJusticeHeading{
            
    padding-bottom: 25px;
       color: white;
       position: relative;
       font-family: var(--site-font);
      

}

#electionsHeading{
            
    padding-bottom: 10px;
       color: white;
       position: relative;
       font-family: var(--site-font);
      

}

#presidentialFeedHeadingMobile, #presidentialFeedHeadingMobile{
    padding-top: 20px;
padding-bottom: 0px;
padding-left: 20px;

  color: white;

        font-size: 18px;
         font-family: var(--site-font);


}
#viewMoreTag {
 

}

#viewMoreTagMobile {
    
    padding-top: 10px;
    

}

#alertBackground
{
    background-color: #01385ab0;
}

#descriptionModalBody
{
    background-color: #002539;
    color: white;
}

#feedContainer{
     padding: 20px;
  
    border-radius: 20px;
      margin-left: 0em;
   margin-right: 0em;
    background-color: #01385ab0;
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
}

#feedContainerRecent{
    padding: 20px;
    width: 100%;
   border-radius: 20px;
     margin-left: 0em;
  margin-right: 0em;
   background-color: #01385ab0;
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
}


#searchFeedContainer{
    padding: 20px;
 width: 625px;
   border-radius: 20px;
     margin-left: 0em;
  margin-right: 0em;
   background-color: #01385ab0;
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);

}


#feedContainerCollection
{
    padding: 20px;
   
    border-radius: 20px;
      margin-left: 0em;
   margin-right: 0em;
    background-color: #01385ab0;
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
   width: 600px;

}

#feedContainerCollectionMobile
{
    padding: 20px;
   
    border-radius: 20px;
      margin-left: 0em;
   margin-right: 0em;
    background-color: #01385ab0;
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
   width: calc(100%)

}

#comingSoon
{
    color: white;
}

.carousel .carousel-control { 
    visibility: hidden; 
}

#feedContainerNormalViewRecentHome
{
    padding: 20px;
    border-radius: 18px;
    margin-top: 0em;
    background-color: rgba(1, 56, 90, 0.24);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
    width: 660px;
}

#feedContainerNormalView{
    padding: 20px;
    border-radius: 18px;
    margin-top: 0em;
    background-color: rgba(1, 56, 90, 0.24);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);


}

#homeIcon
{
    color: white;

  

}



#feedContainerNormalViewRecents{
    padding: 20px;
    border-radius: 18px;
    margin-top: 0em;
    background-color: rgba(1, 56, 90, 0.24);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
    height: 227px;
}


#stateContainer{
    padding: 20px;
   
   border-radius: 18px;
   margin-top: 0em;
   height: auto;
    
   
  
   background-color: rgba(1, 56, 90, 0.24);
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
}
#fullWhiteHouseDocsFeed{
            background-color: #002539;
        color: white;

}

#uscn{
     color: white;
    background-color: #002539;

}
#fullUSCourtNews{
         background-color: #002539;
        color: white;

}
    

#colLeft {

    
    
           
  
}

#colRight {
    
   
           
  
}

#expandIcon
{
  


}
 
#expandButton{
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    top: 22px;
    left: 280px;
    
    color: white;
    border-color:transparent;
    background-color: transparent;
    width: 400px;
    bottom:10px;
  
    
    
}

#expandButtonArguments{

    position: relative;
  background-color: transparent;
    color: white;
    border-color:transparent;

    width: 500px;
    bottom: 15px;
    
    
  

}

#whBreifingFeedHeading{

    
   
    padding-bottom: 25px;
       color: white;
       position: relative;
       font-family: var(--site-font);
  


}

#expandButtonBills{

        

    background-color: transparent;
  
    color: white;
    border-color:transparent;
    
    width: 480px;
    align-self: center;
    
    
    
}

#expandButtonTrendingBills{

        

    
 background-color: transparent;
    color: white;
    border-color:transparent;
  
    width: 480px;
    align-self: center;

    
    
    
}



#expandButtonLaws
{

    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
  
    color: white;
    border-color:transparent;
  
    width: 530px;
    
   
    
}

#expandButtonEnrolledBills{

   background-color: transparent;

    color: white;
    border-color:transparent;
    
    width: 480px;
   

}


#newLaws
{
     position: relative;
   
    color: white;

    

    

}

h6{
     font-family: var(--site-font);
}

#textNote
{
    font-family: var(--site-font);
    color: white;
  padding-left: 20px;
    text-align: center;
}

#billText{
    font-size: 16px;
   font-family: var(--site-font);
   color: white;
   
}

#billTextMore{
    font-size: 20px;
   font-family: var(--site-font);
   color: white;
   
}



#alertBodyText{
    font-size: 24px;
   font-family: var(--site-font);
   padding-top: 0px;
   padding-bottom: 10px;
  
}


#billBodyText{
    font-size: 14px;
   font-family: var(--site-font);
   padding-top: 0px;
   padding-bottom: 10px;
   color: white;
  
}


#cardBodyRep{
    font-size: 14px;
   font-family: var(--site-font);
   padding-top: 0px;
   padding-bottom: 10px;
   color: white;
   margin-left: 102px;
  padding: 20px;
}


#newLaws2
{
     position: relative;
    
    color: white;

    
   
}

#billsEnrolled
{
     position: relative;
    
    color: white;


    

}


#billsEnrolledMobile
{
     position: relative;
    
    color: white;
    height: 180px;

    

}

#billsEnrolled2
{
     position: relative;
    
    color: white;
    height: 180px;

    

}



#stateDepartment
{
     position: relative;
   
    color: white;
    

    

}

#stateDepartment2
{
     position: relative;
    
    color: white;
    

    

}

#theInteriorDepartment
{
     position: relative;
   
    color: white;


    

}

#theInteriorDepartment2
{
     position: relative;
    
    color: white;
    

    

}


#commerceDepartment, #commerceDepartmentMobile
{
     position: relative;
   
    color: white;


    

}

#commerceDepartment2
{
     position: relative;
    
    color: white;
    

    

}

#laborDepartment
{
     position: relative;
   
    color: white;
 

    

}

#laborDepartment2
{
     position: relative;
    
    color: white;
    

    

}


#healthAndHumanServicesDepartment
{
     position: relative;
   
    color: white;
  

    

}

#healthAndHumanServicesDepartment2
{
     position: relative;
    
    color: white;
    

    

}



#educationDepartment
{
     position: relative;
   
    color: white;


    

}

#educationDepartment2
{
     position: relative;
    
    color: white;
    

    

}

#veteransAffairsDepartment
{
     position: relative;
   
    color: white;
 

    

}

#veteransAffairsDepartment2
{
     position: relative;
    
    color: white;
    

    

}


#epa, #epaMobile, #eac, #fec, #gdpUpdates, #inflationUpdates, #wageGrowth, #bureauOfLaborStatistics,
#irsDocuments, #governmentContracts
{
     position: relative;
   
    color: white;


    

}


#rowTest
{

}


#epa2
{
     position: relative;
    
    color: white;
    

    

}

#nsf,#nsf2
{
     position: relative;
    
    color: white;
    

    

}

#nws
{
     position: relative;
   
    color: white;


    

}

#noaa
{
     position: relative;
   
    color: white;


    

}

#noaa2
{
     position: relative;
    
    color: white;
    

    

}

#noaaOSN
{
     position: relative;
   
    color: white;

    

}

#noaaOSN2
{
     position: relative;
    
    color: white;
    

    

}


#nasa, #nasaJPL, #gaoScienceAndTech, #committeeOnScienceSpaceAndTech, #nist, #frDefense, #frState, #frTreasury,  #frJustice, #frInterior, #frAgriculture, #frCommerce, #frLabor, #frHAHS, #frHAUD, #frVeteransAffairs, #frHomelandSecurity, #frEducation
{
     position: relative;
   
    color: white;


    

}

#addNoteModalFooter
{

    
    background-color: #002539;
    border-color: transparent;

}

#nasa2
{
     position: relative;
    
    color: white;


    

}


#homelandSecurityDepartment
{
     position: relative;
   
    color: white;


    

}

#homelandSecurityDepartment2
{
     position: relative;
    
    color: white;
    

    

}

#housingAndUrbanDevelopmentDepartment
{
  color: white;
   



    

}

#energyDepartment, #energyDepartment2
{
     position: relative;
   
    color: white;


    

}

#transportationDepartment, #transportationDepartment2, 
#houseCommitteeOnSmallBusiness, #houseCommitteeOnSmallBusiness2, 
#houseCommitteeOnTransportationAndInfrastructure, #houseCommitteeOnTransportationAndInfrastructure2,
#houseCommitteeOnVeteransAffairs, #houseCommitteeOnVeteransAffairs2
{
     position: relative;
   
    color: white;


    

}

#cdcNewsroom, #cdcNewsroom2
{
     position: relative;
   
    color: white;


    

}

#housingAndUrbanDevelopmentDepartment2
{
     position: relative;
    
    color: white;
    

    

}


#agricultureDepartment
{
     position: relative;
   
    color: white;


    

}

#agricultureDepartment2
{
     position: relative;
    
    color: white;


    

}


#defenseDepartment
{
     position: relative;
   
    color: white;


    

}

#defenseDepartment2
{
     position: relative;
    
    color: white;
    

    

}


#justiceDepartment
{
     position: relative;
   
    color: white;


    

}

#justiceDepartment2
{
     position: relative;
    
    color: white;
    

    

}


#treasuryDepartment
{
 
    
    color: white;
    
  
}


#treasuryDepartment2
{
     
   
    color: white;
  
  
}


#courtModalHeaderMobile{
           background-color: #002539;
 color: white;
 border-color: #002539;
}


#expandButtonMobile{
    position: relative;
    left: 25px;
    
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    width: 300px;
    
  
    
    
}
#courtButtons
{
    
     background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#homePage{
    min-width: 960px;
    
    margin: 0 auto;
}

#courtTable{
    color: white;
    font-size: 16px;
    font-family: var(--site-font);
  
}

#trendingBillsTable{
    color: white;
       font-size: 16px;
    font-family: var(--site-font);
  
}

#courtTableMobile{
    color: white;
      font-size: 16px;
    font-family: var(--site-font);
  
}

#courtModalTable{
    color: white;
       font-size: 16px;
    font-family: var(--site-font);
    background-color: #001d2c
  
}

#trendingBillsModalTable{
    color: white;
       font-size: 16px;
    font-family: var(--site-font);
    background-color: #001d2c
  
}

#courtModalTableMobile{
    color: white;
       font-size: 16px;
    font-family: var(--site-font);
    background-color: #001d2c
  
}
#courtModal{
    
    background-color: #0051835b;

}
#viewMoreButton
{

        background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);


}

#courtModalButtons
{
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#statesModal
{

    background-color: #01385ab0;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);

}


#statesModalButtons{
    position: relative;
    width: 100%;
    table-layout: fixed;
    flex: 1;
   text-size-adjust: 3px;
   background-color: rgb(1, 56, 90);
   border-radius: 20px;
    color: white;
    border: 0px;
    box-shadow: transparent;
    font-size: 14px;
    align-content: center;
margin-right: 5px;
margin-left: 5px;
margin-top: 5px;
margin-bottom: 5px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#appModalButtons{
    position: relative;
    width: 50%;
    table-layout: fixed;
    flex: 1;
   text-size-adjust: 3px;
   background-color: rgb(1, 56, 90);
   border-radius: 20px;
    color: white;
    border: 0px;
    box-shadow: transparent;
    font-size: 14px;
    align-content: center;
margin-right: 5px;
margin-left: 5px;
margin-top: 5px;
margin-bottom: 5px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#statesModalButtonsLast2
{
    position: relative;
    width: 310px;
   text-size-adjust: 3px;
    background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
    font-size: 14px;
    align-content: center;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    border: 0px;

}

#statesModalButtonsLeft{
    position: relative;
    padding-right: 7px;
    
   text-size-adjust: 3px;
       background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
    font-size: 14px;
    align-content: center;
}


#statesModalButtonsRight{
    position: relative;
    
    padding-left: 27px;
   text-size-adjust: 3px;
       background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
    font-size: 14px;
    align-content: center;
}

#statesModalButtonsMid{
    position: relative;
    
    
   text-size-adjust: 3px;
       background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
    font-size: 14px;
    align-content: center;
}



#statesRow {
    align-content: center;
    text-align: center; /* Center the content within the row */
  }

#viewMoreWhiteHouseBRButtonMobile{
    position: relative;
    bottom: 14px;
    left: 54px;
    
    
       background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
    width: 300px;

}



#viewMoreWhiteHouseBRButtonMobile{
    position: relative;
    
    
    
       background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
    width: 300px;

}

#viewMoreTagWhiteHouse{
        position: relative;
        
    top: 33px;
    left: 172px;

    padding-top: 30px;
    padding-bottom: 5px;

}

#viewMoreTagWhiteHouseMobile{
        position: relative;
        
    
    
        bottom: 18px;
        left: 54px;
    padding-top: 20px;
    padding-bottom: 5px;

}
#expandDailyDigestButton
{
    position: relative;
    top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  width: 530px;
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#dailyDigestButton
{
     font-family: var(--site-font);
    position: relative;
    left: 75px;
    padding-top: 5px;
    padding-bottom: 5px;
 height: 40px;
  width: 220px;
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#congressButton
{
     font-family: var(--site-font);
    position: relative;
    left: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
 height: 40px;
  width: 120px;
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#houseButton
{
     font-family: var(--site-font);
    position: relative;
    left: 57px;
    padding-top: 5px;
    padding-bottom: 5px;
 height: 40px;
  width: 120px;
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}


#senateButton
{
     font-family: var(--site-font);
    position: relative;
    left: 63px;
    padding-top: 5px;
    padding-bottom: 5px;
 height: 40px;
  width: 120px;
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#whiteHouseButton
{
     font-family: var(--site-font);
    position: relative;
    left: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
 height: 40px;
  width: 160px;
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#electionButton
{
    font-size: 16px;
     font-family: var(--site-font);
    position: relative;
    left: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
 
  width: 160px;
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}




#usCourtsButton
{
     font-family: var(--site-font);
    position: relative;
    left: 60px;
    padding-top: 5px;
    padding-bottom: 5px;
 height: 40px;
  width: 160px;
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#congressSearchForm
{
    position: relative;
    left: 730px;
    bottom: 35px;

    

}

#whiteHouseSearchForm
{
    position: relative;
    left: 315px;
    bottom: 35px;

}

#noteModalForm
{
    position: relative;
    

}

#supremeCourtSearchForm
{
    position: relative;
    left: 420px;
    bottom: 35px;

}

#searchBar
{
    
}


#expandDailyDigestButtonMobile
{
    position: relative;
        
 
  
    font-size: 14px;
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
    width: 160px;
    left: 50px;
}
#billsViewMoreButton{
    position: relative;
    bottom: 20px;
    
    left: 500px;
    

    
        background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow:transparent;
  width: 400px

}

#addToCollectionButton{
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    border: 0px;
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
  left: 20px;

}

#collection{
    color: white;
}

#collectionNote
{
    padding-top: 5px;
    padding-bottom: 5px;

   /* background-color: #0051835b ;*/
   background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
}

#collectionFeed{
     color: white;
    background-color: #002539;
}

#billsViewMoreButtonMobile{
    position: relative;
    bottom: 18px;
    left: 54px;
    align-self: auto;
   background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
  width: 300px
  

}


#billsEnrolledViewMoreButtonMobile{
    position: relative;
    
bottom: 18px;
    left: 54px;
   background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
  width: 300px

}

#statesDesktopButtons{
  
    width: 50%;
    table-layout: fixed;
    flex: .25;
   background-color: transparent;
    color: white;
    border-color:transparent;
    border-radius: 20px;
    border: 0px;
margin-right: 5px;
margin-left: 5px;
margin-top: 5px;
margin-bottom: 5px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    background-color: #0051835b ;
    color: white;
    border-color:transparent;
    


}

#statesModalButtons {
    position: relative;
    width: 100%;
    table-layout: fixed;
    flex: 1;
    text-size-adjust: 3px;
    background-color: transparent;
    color: white;
    border-color: transparent;
    box-shadow: transparent;
    font-size: 14px;
    align-content: center;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    background-color: #0051835b;
    color: white;
    border-color: transparent;
    text-decoration: none;
    height: 66px; /* Set the desired height */
    max-width: 200px; /* Set the maximum width */
    overflow: hidden; /* Hide any overflowing text */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflow */
  }

  #stateFlagMobile {
    height: 30px; /* Set the desired height */
    width: auto; /* Maintain aspect ratio */
    margin-right: 5px;
  }




  

#desktopLogo
{
    
}





#navbarAboutDesktop{
  font-family: var(--site-font);
    font-weight: bold;
    font-size: 20px;
    color: white;
    padding: 0px;
   position: relative;
   border-color: transparent;
}

#navbarAboutMobile{
font-family: var(--site-font);
    font-weight: bold;
    font-size: 15px;
    color: white;
    position: relative;
    bottom: 4px;
    padding-right: 18px;
    
 
}

#navbarHomeMobile{
    font-family: var(--site-font);
        font-weight: bold;
        font-size: 15px;
        color: white;
        position: relative;
        bottom: 4px;
        padding-right: 2px;
        left: 4px
        
     
    }

#navbarCollectionMobile{
font-family: var(--site-font);
    font-weight: bold;
    font-size: 15px;
    color: white;
    padding: 0px;
   position: relative;
   left: 0px;
   top: 3px;
   padding-right: 3px;

}



#alert{
    background-color: #002539;
    border-color: #002539;
    color: #002539;
}

#navbarCollectionButtonDesktop{

    background-color: rgba(1, 56, 90, 0.24);
     border-color:transparent;
     font-family: var(--site-font);
     margin-right: 5px;
     margin-left: 5px;

}

#appButtons{

    background-color: #03699d;
    
     border-color:transparent;
     font-family: var(--site-font);
    width: 118px;
    

}

#bottomNavbar
{
    font-family: var(--site-font);
    position: relative;
       font-weight: bold;
       margin: auto;
      background-color: #002031; 
   margin-left: 0em;
    
}



#navbarAboutButtonDesktop{
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    border: 0px;
     margin-right: 7px;
     margin-left: 7px;
}


#agencyDropdown{
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    border: 0px;

}

#navbarAboutButtonDesktop{
    background-color: rgba(1, 56, 90, 0.24);
    border-color: rgba(1, 56, 90, 0.24);
     margin-right: 5px;
     margin-left: 5px;
     border-radius: 20px;
     
}

#searchButton
{

    background-color: #0051835b ;
    border-color: rgba(1, 56, 90, 0.24);
     margin-right: 5px;
     margin-left: 5px;
     border-radius: 20px;
     
}

#navbarCollectionButtonMobile{

     background-color: #0051835b ;
     border-color:transparent;
 font-family: var(--site-font);


}

#navbarUpdateButtonMobile
{
    background-color: transparent ;
        border-color:transparent;
font-family: var(--site-font);
right: 200px;
width: 200px;

}



#courtNewsViewMoreButton{
    
        
    background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
    width: 500px;
    
    

}

#courtNewsViewMoreButtonMobile{
    position: relative;
    left:54px;
        
    background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
    width: 300px;
   

}

#noteModalForm
{

  padding: 20px;
}



#noteSaveToCollectionButton
{
    
    background-color: #01466b;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
     width: 200px;
     border-radius: 20px;
}

.button.active
{
    padding-bottom: 20px;
}

#statesViewMore
{

    background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
     width: calc(100%);
    position: relative;
    right: 2000;
     
   


}

#whDocsViewMoreButton
{
    
    
    
    background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
     width: calc(100%);
    padding-top: 20px;

    }



#departmentOfStateViewMoreButton
{
    
    
    background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
     width: 495px;
}

#departmentOfDefenseViewMoreButton
{
    
    
   background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
     width: 495px;
}

#departmentOfTreasuryViewMoreButton
{

    
    background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
     width: 495px;
}

#departmentOfJusticeViewMoreButton
{

    
   background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
     width: 495px;
}

#departmentOfHousingAndUrbanDevelopmentViewMoreButton
{

    
    background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
     width: 495px;
     top: 50px;
}



#courtAppealsViewMoreButton
{
    position: relative;
    bottom: 0px;
    right: 170px;
    
  background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
     width: 400px;
}

#viewMoreWhiteHouseBRButton{
   
    
  
    background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
    width: 530px;

}

#whDocsViewMoreButtonMobile
{
    position: relative;
    
   
    left: 54px;
   background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
     width: 300px;
}

.bgcolor{
    
}

.billsMobile{
   
     
}

.bills2Mobile{
   
    top: 150px 
}

.bills3Mobile{
   
     top: 180px;
}


.bills2Desktop{

    
       
        
}
.bills3Desktop{
       left: 0px;
        top: 60px;
}

#carouselBufferOne{

  
   
}

#homeFooter
{
    color: white;

}

#searchFooter
{
position: fixed;
 bottom: 0;
 width: 100%;
}

#homeFooterAI
{
    color: white;
    

}

#footerItem
{

    padding-left: 20px;
}


#statesSelect
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 

}

#footerNav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

#footerCompanyName
{

    padding-top: 8px;

}

#footerMobile
{

    align-content: center;

}

#navbarLinkMobile
{
    color: white;
    font: 10px;
    align-self: center;
}

#navItemMobile
{

  
}


.parentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust this value based on your design */
  }




#navbarCollectionDesktop{
 font-family: var(--site-font);
    font-weight: bold;
    font-size: 30px;
    color: white;
    
    
   position: relative;
   

}

#statesNavBarDropdown{
    font-family: var(--site-font);
       font-weight: bold;
       font-size: 20px;
       color: white;
    
       
      position: relative;
      
   
   }
   

#navbarStatesDesktop{
    font-family: var(--site-font);
       font-weight: bold;
       font-size: 20px;
       color: white;
      position: relative;
   }



#sectionHeading
{
    margin-left: 2em;
    height: calc(100%);
}

#navbarFoundingDocumentsDesktop{
 font-family: var(--site-font);
    font-weight: bold;
    font-size: 20px;
    color: white;
    padding-top: 20px;
   position: relative;
     border-color: transparent;
   background-color: #023e63b0; 
   

}



#foundingDocumentsDropDown
{

    border-color: transparent;

   
   
   background-color: rgba(1, 56, 90, 0.24);
}

#foundingDocumentsDropDownCountry
{
    border-color: transparent;
    background-color: rgba(1, 56, 90, 0.24);
}

#offcanvasDropdownHeaderThree
{
    color: white;
    font-size: 20px;
}

#docsDropdown
{
    justify-content: center;
    align-items: center;
    background-color: #002031; 
}

 /*   background-color: transparent !important;
  color: inherit !important;}*/

#docsDropdownItem:focus,
#docsDropdownItem:active,
#docsDropdownItem:hover {
    background-color: #21cff5;
  color: inherit !important;

}





#docsDropdownItem
{
    color: white;
    background-color: #002031; 
    
}

#notificationButton
{

    float: right;
    display: inline;
background-color: transparent;
/* background-color: #0051835b ;*/
color: white;
border-color: transparent;
margin-right: 10px;
padding: 0px;
}




#legislativeFeedDropdown
{
    border-color: transparent;
    background-color: #023e63b0; 
    position: relative;
    width: 200px;
    left: 100px;
}

#branchesDropdownMobile
{
   

    border-color: transparent;
   background-color: transparent; 
   /*was 170*/
   width: 170px;
   font-size: 20px;
   

}

#statesDropdownMobile
{
   

    border-color: transparent;
   background-color: transparent; 
   /*was 170*/
  right: 200px;
   

}


#branchesDropdownItemMobile
{
    color: white;
    background-color: #001d2c; 
    font-size: 20px;
  
}

#branchesDropdownMenuMobile
{
    background-color: #001d2c;  
   
    width: 250px;
    height: 578px;
  
    
}

#statesDropdownMenuMobile
{
    background-color: #001d2c;  
   
   height: 100px;
   width: 200px;
   right: 200px;
  
    
}
#topicsDropdownMobile
{
    

    border-color: transparent;
   background-color: #023e63b0; 
   left: 300px;
    padding-right: 20px;
   width: 120px;

}

#changeBranchDropdownMobile
{   
   
padding-bottom: 40px;
padding-left: 200px;

    

}



#changeTopicDropdownMobile
{   
   
    padding-top: 10px;
    padding-bottom: 10px;
  
    

}







#legislativeRowMobile
{
    padding-bottom: 7px;
}

#foundingDocumentsRowMobile
{
    padding-top: 10px;
    padding-left: 6px;

}



#mobileFoundingDocumentsDropDown
{
   font-family: var(--site-font);
    border-color: transparent;
    font-size: 15px;
    color: white;

   background-color: #0051835b ;

}

.offcanvas-header .close
{
    color: white;
    border-color: white;
    opacity: 1;

}




.modal-header .close
{
    color: white;
    border-color: white;
    opacity: 1;

}






.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: white;
    background-color: #fff;
    border-color: white;
    border-bottom: none;
}

.nav-tabs {
    color: white;
    border-bottom: none;
}


.nav-tabs .nav-link:hover{
    color: white;
    border-bottom: none;
}

#billProgress
{
    color: white;
}

#billProgressModal
{
    color: white;
}

#statesAlert
{
    
    background-color: #002031; 
    border-color: transparent;
}

#audioIframe
{

    border-color: transparent;
    align-content: center;    
}


#navbarSpacer
{
    padding: 5px;
}

#appIcons
{
    margin: 5px;
  
}

#appIconsDesktop
{
    margin-right: 3px;
  
}

#iconSpacer
{
    width: 2px;
}


#donateButton
{
    margin-top: 20px;
display: block; 
margin: 0 auto; 
    margin-top: 8px;
    background-color: #1994d1;
    border-color:transparent;
    margin-bottom: 8px;


}

#govglanceLink
{
    color: #1994d1;
}



#selectHomeCountry {
    width: 100%; /* Make the button take full width */
    max-width: 175px; /* Set a max-width */
    margin: 20px auto 15px; /* Center the button */
    text-size-adjust: 3px;
    background-color: #016cae6e;
    color: white;
    border-color: transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.512);
    font-size: 16px;
    align-content: center;
    border-radius: 25px;
    padding: 10px;
    white-space: nowrap; /* Prevent text from wrapping */
}



#selectHomeCountryLock {

    
    width: 100%; /* Make the button take full width */
    max-width: 175px; /* Set a max-width */
    margin: 20px auto 15px; /* Center the button */
    text-size-adjust: 3px;
    background-color: #023b5f6e;
    color: white;
    border-color: transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.512);
    font-size: 16px;
    align-content: center;
    border-radius: 25px;
    padding: 10px;
    white-space: nowrap; /* Prevent text from wrapping */
}

.button-container {
    text-align: center; /* Center buttons within the container */
    margin: 0 auto; /* Center the container */
}

#selectHomeCountryPage {
    display: block;
    width: 11%;
    margin: 15px;
    background-color: #016cae6e;
    color: white;
    border-color: transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.512);
    font-size: 16px;
    border-radius: 25px;
    padding: 10px;
    
}
#selectHomeCountryPageLock {
   
    background-color: #023b5f6e;
    display: block;
    width: 11%;
    margin: 15px;

    color: white;
    border-color: transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.512);
    font-size: 16px;
    border-radius: 25px;
    padding: 10px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

#selectHomeCountryDesktop{
    margin-top: 20px;
    display: block; 
    width: 20%;      
    margin: 0 auto; 
    margin-bottom: 15px; 
    text-size-adjust: 3px;
    background-color: #016cae6e;
    color: white;
    border-color: transparent;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.512);
    font-size: 20px;
    align-content: center;
    border-radius: 25px;
    padding: 10px;
    
}

#addToCollectionButtonMobile
{
    position: relative;
    padding-top: 5px;
padding-bottom: 5px;
background-color: transparent;
/* background-color: #0051835b ;*/
color: white;
border-color:transparent;
box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
left: 20px;
    
}


#branchHeadingMobile2{

    color: white;
  text-align: left;
  padding-left: 20px;
font-family: var(--site-font);
margin: auto;
padding-bottom: 10px;

}

#headingSelectHomeCountry{
    color: white;
    position: relative;
    table-layout: fixed;
    font-family: var(--site-font);
    text-align: center; /* Center the text horizontally */
    margin: 0 auto; /* Center the element horizontally */
    top: 40%; /* Move the element down by 50% of its own height */
    transform: translateY(-50%); /* Adjust for the element's height to center vertically */
    margin-bottom: 5px;
    margin-top: 0px;
}

#headingSearch{
    color: white;
    position: relative;
    table-layout: fixed;
    font-family: var(--site-font);
    text-align: center; /* Center the text horizontally */
    margin: 0 auto; /* Center the element horizontally */
    top: 50%; /* Move the element down by 50% of its own height */
    transform: translateY(-50%); /* Adjust for the element's height to center vertically */
    margin-bottom: 5px;
    margin-top: 20px;
}

#mobileLogoOnBoard{
    
    font-size: 30px;
    margin: auto;
    position: relative;
    bottom: 0px;
    display: block;
    padding-bottom: 0px; /* Add padding to push content below */
    margin-bottom: 0px; /* Or use margin-bottom instead of padding-bottom */
    padding-top: 0px;
}
#mobileLogoOnBoardDesk{
    
    font-size: 30px;
    margin: auto;
    position: relative;
    bottom: 0px;
    display: block;
    padding-bottom:0px; /* Add padding to push content below */
    margin-bottom: 0px; /* Or use margin-bottom instead of padding-bottom */
   
}

#searchLogo{

    font-size: 30px;
    margin: auto;
    position: relative;

    display: block;

 
}

#backButtonMobile
{
border-color: transparent;
    border-radius: 50px;
    background-color: #03699d;
    position: relative;
    top: 0;
   bottom: 2050px;
    left: 30px;
    right: 0;
    height: 60px;
    width: 60px;
 
    margin-left: calc(20%);
    
}


#aiNavIcon
{
   
    margin: auto;
   color: white;
}

#aiNavLinkMobile
{
padding: 10px;
}

#loadMoreBG
{
    background-color: #002539
}

#ggButton{
    
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    border: 0px;
     margin-right: 5px;
     margin-left: 10px;
  }

  #searchCountry
  {
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
    border: 0px;
     margin-right: 10px;
     margin-left: 5px;
     font-size: 16px;
     background-color: #0051835b ;
  }

  #stateRepsState {
    background-color: rgb(1, 56, 90); /* Background color */
    border-radius: 20px; /* Rounded corners */
    border: 0; /* No border */
    margin-right: 10px; /* Right margin */
    margin-left: 5px; /* Left margin */
    font-size: 16px; /* Font size */
    color: white; /* Text color */
    padding: 10px; /* Padding */
  }

 

  #ggSearch
  {
    background-color: #0051835b ;
    border-radius: 20px;
    border: 0px;
    color: white;
    align-self: center;
    width: 500px;
    height: 36px;
    font-size: 16px;
    padding: 20px;
    
    
  }
  


  #ggSearch2OG
  {

    /* has opacity */
    background-color: #0051835b ;
    border-radius: 20px;
    border: 0px;
    color: white;
    align-self: center;
    width: 275px;
    height: 36px;
    font-size: 16px;
    padding: 20px;
  
  }
  

  #ggSearch2
  {
    background-color: #0051835b ;
    border-radius: 20px;
    border: 0px;
    color: white;
    align-self: center;
    width: 275px;
    height: 36px;
    font-size: 16px;
    padding: 20px;
  
  }

  #collectionButtonPadding
  {

  }

  #buttonSpacer
  {
    width: 10px;
  }


  #ggSearchCollection
  {
    background-color: #0051835b ;
    border-radius: 20px;
    border: 0px;
    color: white;
    align-self: center;
    width: 1350px;
    height: 36px;
    font-size: 16px;
    padding: 20px;
    
  }

  #ggSearchCollectionMobile
  {
    background-color: #0051835b ;
    border-radius: 20px;
    border: 0px;
    color: white;
    align-self: center;
    width: 400px;
    height: 36px;
    font-size: 16px;
    padding: 20px;
    
  }

  #stateFlagDropdown
  {
    height: 24px;
    width: 24px;
  }

  #mobileNavbarSpacer
{
 
    height: 30px;
}

  .autocomplete-input:-internal-autofill-selected {
    background-color: initial !important;
    color: initial !important;
}

#searchSpacer
{

height: 50px;

}

#errorText
{
    color: white;
}


#repPhotoDiv
{
width: 100px;
border-radius: 200px;
height: 100px;
background-position: center;
background-size: cover;
border-radius: 50%;



}

#repPhoto
{
width: 100px;
border-radius: 200px;
height: 100px;
background-position: center;
background-size: cover;
border-radius: 50%;



}
#repPhotoDiv {
    display: inline-block;
    vertical-align: middle;
    color: white;
  }

  #repPhotoMobile
{

border-radius: 200px;

background-position: center;
background-size: cover;
background-size: auto;
width: 100px; 
  height: 100px;


}
#repPhotoDivMobile {
    display: inline-block;
    vertical-align: middle;
    color: white;
    margin: 0;
  }
  
  .repDisplay {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px; 

  }
  .repDisplayMobile {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    color: white;

  }

  .mobileContentWrapper {
  display: flex;
  align-items: center;
}

#repPhotoDivMobile {
  margin-right: 10px; 
}

.repDisplayMobile {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }
  
  .repDisplayMobile span {
    margin-bottom: 5px;
margin-right: 10px;
  }

  #profileImageDesk {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    overflow: hidden;
  }

  #profileImage {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    overflow: hidden;
  }

  #profileImageRep {
    width: 55px;
    height: 55px;
    border-radius: 100%;
    overflow: hidden;
  }

  #profileImageIcon {
    width: 70px;
    height: 70px;
 
  }


  #profileImageState {
    width: 50px;
    height: 50px;

    overflow: hidden;
  }

  #profileImage-imageicon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    color: white;
  }
  
  #profileImage-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #profileImage-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .mobileContentWrapper {
    display: flex;
    align-items: center;
  }

  #pageprofileImage {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    overflow: hidden;
  }
  
  #pageprofileImage-image {
   
    object-fit: cover;
  }


  #feedDisplay{
    padding: 10px;
    background-color: #002031; 
        color: white;


        
}

#feedName
{

    padding-bottom: 0px;
 
    
      color: white;
    
            font-size: 18px;
             font-family: var(--site-font); 
}

#feedDisplayCaro{
    
 
        color: white;


        
}

#searchFormPage
{
 
padding-right: 10px;
padding-left: 10px;
padding: 10px;

    color: white;
}

#presidentCarousel {
    
    position: relative;

    margin-top: 0.5em;
    margin-bottom: 1em;
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: #012c477d;
   box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.521);
    border-radius: 20px;
    height: auto;
    width: auto;
   
    
      
      
   
   
      
   }

   #balanceCarousel {
    
    position: relative;

    margin-top: 0.5em;
    margin-bottom: 1em;
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: #012c477d;
   box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.521);
    border-radius: 20px;
    height: 420px;
    width: 427px;
   
    
      
      
   
   
      
   }

   #repContainer{
    padding: 20px;
   
   border-radius: 18px;
   margin-top: 0em;
   width: 390px;

   background-color: rgba(1, 56, 90, 0.24);
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
}

#repContainerDesktop{
    padding: 20px;
   
   border-radius: 18px;
   margin-top: 0em;
   width: 500px;
height: 170px;
   background-color: rgba(1, 56, 90, 0.24);
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
}

#repContainerDesktopOnPage{
    padding: 20px;
   
   border-radius: 18px;
   margin-top: 0em;
   width: 500px;
height: 200px;
   background-color: rgba(1, 56, 90, 0.24);
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
}


#repContainerDesktopOnPageExec{
    padding: 20px;
   
   border-radius: 18px;
   margin-top: 0em;

height: 195px;
   background-color: rgba(1, 56, 90, 0.24);
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
}

#repContainerDesktopOnPageCaro{
    padding: 20px;
   
   border-radius: 18px;
   margin-top: 0em;

height: 200px;
   background-color: rgba(1, 56, 90, 0.24);
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.233);
}


#repCarouselDisplay{
    
    position: relative;
    margin-top: 0.5em;
    margin-bottom: 1em;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #012c477d;
   box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.521);
    border-radius: 20px;
    height: 250px;
    
  
   }


   #repCarouselDisplayDesktop{
    
    position: relative;
    margin-top: 0.5em;
    margin-bottom: 1em;
    padding-top: 20px;
    padding-bottom: 10px;
    background-color: #012c477d;
   box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.521);
    border-radius: 20px;
    height: 300px;
    
  
   }

   #repCarouselHeading
{   
    margin-left: 30px;
       color: white;
       position: relative;
       right: 0px;
        font-family: var(--site-font);
       
      
      
  
}

#presidentViewMoreButton
{
    
    
    
    background-color: transparent;
    color: white;
    border-color:transparent;
    box-shadow: transparent;
     width: calc(100%);
    

    }

    #profileImageOnPage {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        overflow: hidden;
      }
      
      #profileImage-imageOnPage {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      #presidentialFeedHeadingMobileSmaller
{
    color: white;
    position: relative;
    font-size: 17px;
     font-family: var(--site-font);
}

#presidentialFeedHeadingMobileSmallerV
{
    color: white;
    position: relative;
    font-size: 16px;
     font-family: var(--site-font);
     margin-left: 5px;
}

#recentsHeading{
    padding-top: 10px;
padding-bottom: 0px;
padding-left: 20px;

  color: white;

        font-size: 18px;
         font-family: var(--site-font);


}

#billBodyTextSource{
    font-size: 18px;
   font-family: var(--site-font);
   padding-top: 0px;
   padding-bottom: 10px;
   color: white;
  
}

#profileImage-imageOnPageDepartment {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #profileImage-imageState {
    width: 50px;
    height: 50px;
     border-color: pink;
    border-radius: 100px;
    
  }

  #profileImageOnPageDepartment {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    overflow: hidden;
  }

  #profileImageOnPageDepartmentIcon {
    width: 70px;
    height: 70px;
    color: white;
  }
  

  #profileImageOnPageDepartmentState {
    width: 50px;
    height: 50px;
  
    overflow: hidden;
  }
  

  #declarationIframe
  {

    
  }


  #textPage
  {
    width: 50%;
    margin: auto;
  }
  #textPageMobile
  {
    width: 90%;
    margin: auto;
  }

  #legislativeGroups {
 
    justify-content: center;  /* Centers horizontally */
    align-items: center;      /* Centers vertically */

  }


  .clickable {
    transition: transform 0.2s, background-color 0.2s;
   }
   
   .clickable:hover {
    transform: scale(1.05);
  
    cursor: pointer;
   }

   .custom-bottom-navbar {
    position: fixed;
    bottom: 80px; /* Moves it above #navbarMobileBottom */
    width: 100%;
    z-index: 1100; /* Ensure it's above the bottom navbar */
    background-color: transparent; /* No background */
    display: flex;
    justify-content: space-around; /* Align items evenly */
    padding: 10px 0;
  }
  
  /* Styling for circular buttons */
  .nav-circle {
    width: 60px; /* Matches the size of #offcanvasButton */
    height: 60px;
    background-color: #03699d; /* Circle background color */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  
  .nav-text {
    font-size: 12px;
    color: white;
    margin-top: 5px;
    text-align: center;
  }
  

  #menuSpacer
  {
    height: 500px;
  }
  
 

  #navbarMobileTop {
    position: fixed;
    
    width: 100%;
    z-index: 1000; /* Same as navbarMobileBottom */
    background-color: transparent;
}

#searchContainer {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 40px;
    width: 100%; /* Make the whole container span full width */
    
}

#mobileDropdown {
    flex-shrink: 0;  /* Prevent the dropdown from shrinking */
    border-color: transparent;
}



#offcanvasButton {
    flex-shrink: 0; /* Prevent the button from shrinking */
    width: 50px;
    height: 50px;
    background-color: #03699d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}